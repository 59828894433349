import React, { useEffect, useState }from 'react'
import {useTranslation} from "react-i18next"
import {
  TextField,
  Button,
Select,
MenuItem,
makeStyles 
} from '@material-ui/core'
import {
  Link
} from "react-router-dom"
/*import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
*/
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import integration from '../pics/integration.png'
import logo from '../pics/logo.png'
import osmo from '../pics/osmo2.png'
import bi from '../pics/webbi.png'
import consultation from '../pics/consult.png'
import kuva from '../pics/etu.png'
import Slider from "react-slick"
import Carousel from './Carousel.js'
import '../fonts/fonts.css'
import '../fonts/RampartOne-Regular.ttf'
import '../fonts/BungeeInline-Regular.ttf'
import '../fonts/Syncopate-Regular.ttf'


const column = {
  float: 'left',
  width: '50%'
}
const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
  },
  link: {
    margin: theme.spacing(1),
    textDecoration: 'none',
  },
  button: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(3),
    color: '#ffffff',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#ff3366',
    },
  },
  homeButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
  cottagesButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  profileButton: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  // Add more button styles here
  aboutButton: {
    backgroundColor: '#9c27b0',
    '&:hover': {
      backgroundColor: '#7b1fa2',
    },
  },
  contactButton: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: '#f57c00',
    },
  },
}));


const Home = ({care,walking,ocare,owalking, walkers,handlers,animals }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [type, setType] = useState('')
  
  const classes = useStyles();

  //ilmoituksen diville värin arpominen
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const divcolors=['green','red','#C1B1D1', 'grey', 'pink','#A1B1C1','#D1A1D1','#B1B1B1','#F1F1F1','#A1A1A1','#C1C1C1','#C1F1D1']
  

  if(type===''|| type==='kaikki'){
    //<p align='center' width='50%'><img src={kuva} width='100%' /></p>
    /**
     * 
     * <div class="row">
          <div class="column"></div>
          <div class="column"></div>
        </div>
     */
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      const tyyli={
        width: '90%',
        left: 12, 
        zIndex:1, 
        top:'100px',
        border: '1px solid #12343b',
        borderRadius: '2px',
        opacity: 0.99, 
        backgroundColor:'#ffffff', 
        fontSize:'14px', 
        fontFamily:'Helvetica', 
        textAlign:'center'
      }
      const head={
        fontSize:'34px', 
        fontFamily:'Helvetica', 
        textAlign:'center'
      }
      return(
        <Carousel style={{tyyli}}>
          <div>
            <h2 className="font-face-gm">{t('home.header1', {framework:'React'})}</h2>
            <ul className="font-face-gm-text-right">{t('home.text1', {framework:'React'})}
            <br />
            {t('home.text2', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-left">{t('home.text3', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-left">{t('home.text4', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-right">{t('home.text5', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-left">{t('home.text6', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-left">{t('home.text7', {framework:'React'})}</ul>
            <ul className="font-face-gm-text-left">{t('home.text8', {framework:'React'})}</ul>
<img src={bi} alt="BI"/><br /><br /><br /><br /><br /><br />
          </div>
          <div>
          <h2 className="font-face-gm">{t('home.header2', {framework:'React'})}</h2>
          <ul className="font-face-gm-text-right">{t('home.text9', {framework:'React'})}</ul>
          
          <ul className="font-face-gm-text-right">{t('home.text10', {framework:'React'})} 
          <br /><br />{t('home.text11', {framework:'React'})} 
          <br /><br />{t('home.text12', {framework:'React'})}</ul>
          <img src={integration} alt="Process"/>
            <br /><br /><br /><br />
          </div>
          <div style={{tyyli}}>
          <h2 className="font-face-gm">{t('home.header5', {framework:'React'})}</h2>
          <ul className="font-face-gm-text-right">{t('home.text13', {framework:'React'})}
          <br /><br />
          {t('home.text14', {framework:'React'})}
          <br/><br /> 
          {t('home.text15', {framework:'React'})}</ul>
            <img src={consultation} alt="Consultation"/>
            <br /><br /><br /><br />
          </div>
          <div style={{tyyli}}>
          <h2 className="font-face-gm">{t('home.header3', {framework:'React'})}</h2>
          <ul className="font-face-gm-text-left">{t('home.text16', {framework:'React'})}</ul> 
          <ul className="font-face-gm-text-right">{t('home.text17', {framework:'React'})}</ul>
          <ul className="font-face-gm-text-right">Osmo Leppäniemi</ul>
          <ul className="font-face-gm-text-right">osmo@hubioy.fi</ul>
          <ul className="font-face-gm-text-right">+358 10 582 9810</ul>
          <img src={osmo} alt="Osmo"/>
          </div>
        </Carousel>
        
    )
}
}
export default Home


/**
 * 
 * 
 *  <div style={{ height:"200px",float: 'left',marginLeft:'130px',
  width: '50%', zIndex:1, border: '0px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, fontSize:'14px', fontFamily:'Helvetica', textAlign:'center', alignItems:'center' }}>
                    <br />
                    <h2>Asiantuntemusta tarpeidesi mukaan</h2>
                    <p>Hubi Oy on vuonna 2023 perustettu yritys, joka tarjoaa IT-konsultointia, tukea hankintoihin ja strategiaan sekä toteutuksia integraatioihin, tietojen varastointiin, sovelluskehitykseen ja tiedolla johtamiseen kahdenkymmenen vuoden kokemuksella. </p>

      </div>
      <div style={{ float: 'left', width: '20%',height:"10px", zIndex:1, border: '0px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, fontSize:'14px', fontFamily:'Helvetica', textAlign:'center', alignItems:'center' }}>
                    <br />
                    <img src={osmo} alt="Osmo"/>
      </div>
      <div style={{width: '99%',margin:'-10px', zIndex:11, height:'400px', border: '0px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, backgroundRepeat:"no-repeat", fontSize:'14px', fontFamily:'Helvetica', textAlign:'center', alignItems:'center' }}><br />
        
      </div>
      
      <div style={{width: '99%',margin:'-10px', zIndex:11, border: '1px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, backgroundColor:'#ffffff', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <h3>Integraatiot</h3>
                    <ul>
                        Tieto kulkemaan automatisoidusti järjestelmien välillä
                    </ul>
                    <img src={taustakuva} alt="Prosessi" width='50%'/>
                    
      </div>
      <div style={{width: '99%',margin:'-10px', zIndex:11, border: '1px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, backgroundColor:'#ffffff', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <h3>Konsultoinnit</h3>
                    <ul>
                        Tukea valintoihin IT-hankinnoissa
                    </ul>
                    <ul>
                        Tietojen tehokkaampi hyödyntäminen, tiedolla johtaminen
                    </ul>
                    <ul>
                        Digitstrategiat, tietohallintostrategiat, tietotilinpäätökset
                    </ul>

      </div>
      <div style={{width: '99%',margin:'-10px', zIndex:11, border: '1px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, backgroundColor:'#ffffff', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <h3>Ota yhteyttä</h3>

                    <ul>
                        osmo@hubioy.fi
                    </ul>
                    <ul>
                        +358 50 353 4737
                    </ul>
                    <br /><br /><br />
      </div>
      
      </>
 */
