import React, { useState, useEffect, Component, Suspense} from 'react'
import { useQuery, useApolloClient, useSubscription } from '@apollo/client'
import {useTranslation} from "react-i18next"


//import Activities from './components/Activities'
import Prices from './components/Prices'
import Hubioy from './components/Hubioy'
import Consultation from './components/Consultation'
import Appdev from './components/Appdev'
import Data from './components/Data'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
//import Pics from './components/Pics'
//import Panorama from './components/Panorama'
//import Videos from './components/Videos'
import Contact from './components/Contact'
import { SocialIcon } from 'react-social-icons';
import Home from './components/Home'
import Gdpr from './components/Gdpr'
import Faq from './components/Faq'
//import User from './components/User'
//import Admin from './components/Admin'
//import Header from './components/Header'
import Headerpublic from './components/Headerpublic'
//import Headeradmin from './components/Headeradmin'
import Info from './components/Info'
import logo from './pics/logo.png'
import Termsofuse from './components/Termsofuse'
import Instructions from './components/Instructions'
import taustakuva from './pics/etu.png'
import ylabanner from './pics/webhubi.png'
//import taustamobile from './pics/taustamobile.png'
//import RouteChangeTracker from './components/RouteChangeTracker'
//import { ALL_ORGANS, ALL_COMMENTS, ALL_USERS, ALL_LINKS, ALL_VOTES, ALL_INITIATIVES, ALL_MUNICIPALITIES, ALL_SECTIONS,  ORGAN_ADDED,  ALL_PROPOSALS, ALL_MEETINGS } from './queries'
import Footer from './components/Footer'
//import background from "./pics/taustapieni_mv.png";
//import tavi from "./pics/2.png";
import './App.css'
//import WordSection from './components/WordSection'
//import ForgetPassword from './components/ForgetPassword'
//import NewPassword from './components/NewPassword'
import HomeIcon from '@material-ui/icons/Home'
import HubIcon from '@mui/icons-material/Hub';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import StorageIcon from '@mui/icons-material/Storage';
import SchemaIcon from '@mui/icons-material/Schema';
import ContactsIcon from '@mui/icons-material/Contacts';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LanguageIcon from '@mui/icons-material/Language';
import Player from './components/Player'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom"
//import { MuiThemeProvider, createMuiTheme } from 'material-ui/core/styles'
import {
  Container,
  Button,
  AppBar,
  IconButton,
  makeStyles,
//fade
} from '@material-ui/core'
//import ReactGA from 'react-ga'
//ReactGA.initialize('UA-198353084-1')
import TagManager from 'react-gtm-module'
import anime from "animejs/lib/anime.es.js"

const Notify = ({ errorMessage }) => {
  if ( !errorMessage ) {
    return null
  }

  return (
    <div style={{color: 'red'}}>
      {errorMessage}
    </div>
  )
}

const App = () => {
  //let loggedin = ''
  const [user, setUser] = useState(null) 
  const [usertype, setUsertype] = useState(null) 
  const [log, setLogout] = useState(null) 
  
  /***
   * #FFFFFF
   * #FBA92C
   * #9E9E9E
   * #565656
   * #141414
   * TAI
   * #C8B7A6
   * #7D94B5
   * #C29591
   * #703F37
   * #B6C199
   * 
   */
  const login = (user) => {
    setUser(user)
    notify(`tervetuloa ${user}`)
    //setMessage(`tervetuloa ${user}`)
  }
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WCF466Q' });
  }, [])
  
  const [token, setToken] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null) 
 /* const organsw = useQuery(ALL_ORGANS, {
    pollInterval: 100000  })

  const users = useQuery(ALL_USERS, {
    pollInterval: 100000  })

  let userss=[]
  if(users.data){
    userss=users.data.allUsers
  }
  const votes = useQuery(ALL_VOTES, {
    pollInterval: 100000  })
*/
  const client = useApolloClient()
  //const results= Object.values(organs.data)
  //console.log(organs.data.allOrgans)
/*
  const updateCacheWith = (addedOrgan) => {
    const includedIn = (set, object) => 
      set.map(p => p.id).includes(object.id)  

    const dataInStore = client.readQuery({ query: ALL_ORGANS })
    if (!includedIn(dataInStore.allOrgans, addedOrgan)) {
      client.writeQuery({
        query: ALL_ORGANS,
        data: { allOrgans : dataInStore.allOrgans.concat(addedOrgan) }
      })
    }   
  }

  useSubscription(ORGAN_ADDED, {
    onSubscriptionData: ({ subscriptionData }) => {
      const addedOrgan = subscriptionData.data.organAdded
      notify(`${addedOrgan.name} added`)
      updateCacheWith(addedOrgan)
    }
  })
*/
  useEffect(() => {
    document.title = "Hubi Oy"
    const token = localStorage.getItem('hubi-token')
    if ( token ) {
      setToken(token)
    }
    const useri = localStorage.getItem('usertype')
    if ( useri) {
      setUsertype(useri)
    }
    const email = localStorage.getItem('email')
    if(email){
      setUser(localStorage.getItem('email'))
    }
   /* ReactGA.event({
      category: 'Open App',
      action: 'Opening kunta.live',
      label: 'Opening'
    })
    */
  }, [])
  
  const notify = (message) => {
    setErrorMessage(message)
    setTimeout(() => {
      setErrorMessage(null)
    }, 5000)
  }
//"repeat|repeat-x|repeat-y|no-repeat|initial|inherit"
  /*if  (window.innerWidth < 900) {
  return (
      <Suspense fallback="loading">
      <div  style={{ 
          width:'100%',
          position: 'absolute', 
          font: 'Verdana',
          //backgroundColor: '#fefbe9',
          //'#52ab98',
          //#c8d8e4
          backgroundRepeat: 'no-repeat',
          //backgroundImage:`url(${taustakuva})`,
          backgroundAttachment: 'fixed',
          //borderRadius: '16px',
          //border: "2px solid #73AD21"
        }}>
        
      <Container>
        <Router>

          <AppBar position="static" style={{ right:'0px', position: 'fixed', top:'25px', bottom: 0,  height:'0px', backgroundColor:'#266150',zIndex:'5'}} height='10px'>
              <Headerpublic style={{zIndex:8}} />
              <div  style={{right:'5px', position: 'fixed',top:'2%',  height:'20px'}} height='20px' position align='right' z-index='9  '>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary" onClick={() => i18n.changeLanguage('fi')}>fi</Button>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('en')}>en</Button>
                <Button variant="outlined" style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black', fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('sv')}>sv</Button>
              </div>
          </AppBar>
          <br />
          <br />
          <br />
          <Notify errorMessage={errorMessage} />
          <Switch>
            <Route path="/home">
              <br />
              <Home />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/prices">
              <Prices />
            </Route>
            <Route path="/gdpr">
              <Gdpr />
            </Route>
            <Route path="/info">
              <Info  />
            </Route>
            <Route path="/termsofuse">
              <Termsofuse   />
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
          <br /><br /><br /><br />
          <Footer/>
        </Router>
      </Container>
      </div>
      </Suspense>
    )
    }*/
    /**
 * 
 * 
 * Sand Tan: #e1b382

Sand Tan Shadow: #c89666

Night Blue: #2d545e

Night Blue Shadow: #12343b
<img src={tavi} width='85%'/>
 */
/*<div style={{position: 'absolute',top:'22px', height:'200px', width: '100%',margin:'-10px', zIndex:11, border: '0px solid #12343b',borderRadius: '2px',
opacity: 0.99, backgroundImage:`url(${ylabanner})`, backgroundRepeat: 'no-repeat',fontSize:'14px', fontFamily:'Helvetica', textAlign:'right'}}>
</div>
*/
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
  },
  link: {
    margin: theme.spacing(0),
    textDecoration: 'none',
  },
  button: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(3),
    color: '#ffffff',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#ff3366',
    },
  },
  homeButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
  cottagesButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  profileButton: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  // Add more button styles here
  aboutButton: {
    backgroundColor: '#9c27b0',
    '&:hover': {
      backgroundColor: '#7b1fa2',
    },
  },
  contactButton: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: '#f57c00',
    },
  },
}));

const classes = useStyles();
    return (
      <>
        <Suspense fallback="loading">
        <Container style={{marginLeft: '0px'}}>
          <div  style={{ 
            width:'100%',
            position: 'absolute', 
            font: 'Verdana',
            marginLeft: '0px',
            //backgroundColor: '#fefbe9',
            //'#52ab98',
            //#c8d8e4
            backgroundRepeat: 'no-repeat',
            //backgroundImage:`url(${taustakuva})`,
            backgroundAttachment: 'fixed',
            //borderRadius: '16px',
            //border: "2px solid #73AD21"
          }}>
            
              <Router>
              <div style={{zIndex:1,width:'35px',position:'fixed',textAlign:'center', top:'25px', right:'5px', backgroundColor:'#FFFFFF',border: '1px solid #12343b'}}>
                <Button style={{marginLeft:'-15px',height:'17px', color:"#000000",border: '0px solid black',fontSize: "11px" }} onClick={() => i18n.changeLanguage('fi')}>fi</Button>
                <Button style={{marginLeft:'-15px',height:'17px', color:"#000000",border: '0px solid black',fontSize: "11px" }} onClick={() => i18n.changeLanguage('en')}>en</Button>
                <Button style={{marginLeft:'-15px',height:'17px', color:"#000000",border: '0px solid black', fontSize: "11px" }} onClick={() => i18n.changeLanguage('sv')}>sv</Button>
              </div>
                <div style={{left:0,position:'fixed',top:'0px', height:'20px', width: '100%', marginRight:'100px', zIndex:11, border: '1px solid #12343b',borderRadius: '2px',
                      opacity: 0.99,  fontSize:'14px',backgroundColor:'#E3F4BE',fontFamily:'Helvetica', textAlign:'right'}}>
                        <a href="tel:+358105829810"><PhoneIcon  style={{height:'15px'}}/></a>
                        <a href='mailto:osmo@hubioy.fi' target='0'><EmailIcon  style={{height:'15px'}}/></a>
                  <a href='https://www.linkedin.com/company/hubi-oy' target='0'><LinkedInIcon url="https://www.linkedin.com/company/hubi-oy" style={{height:'15px'}}/></a>
                  <a href='https://wa.me/358400880236' target='0'><WhatsAppIcon url="https://whatsapp.com/hubioy" style={{height:'15px'}}/></a>
                </div>
                <div style={{height:'70px',width:'99%',marginLeft:'-10px', border: '0px solid #12343b',borderRadius: '2px',
                      opacity: 0.99, fontSize:'14px', fontFamily:'Helvetica', alignContent:'center', textAlign:'center'}}>
                  <Link to="/" className={classes.link}><img src={ylabanner} alt='Hubi Oy'/></Link>
                </div>
                <div style={{position:'fixed',bottom:'15px', backgroundColor:'#E3F4BE',height:'50px',width:'100%',left:0, zIndex:12, border: '1px solid #12343b',borderRadius: '2px',
                      opacity: 0.99, fontSize:'14px', fontFamily:'Helvetica', textAlign:'center',verticalAlign:'bottom'}}>
                        <Link to="/hubioy" className={classes.link}><IconButton style={{height:'45px', color:"#000000",border: '0px solid black', fontSize: "10px", verticalAlign:'bottom'}}>
                          <table style={{height:'10px', verticalAlign:'bottom'}}>
            <tr style={{height:'10px', verticalAlign:'bottom'}}><td style={{height:'10px', verticalAlign:'bottom'}}><HomeIcon /></td></tr><tr style={{verticalAlign:'bottom',width: '10px', height:'10px'}}><td style={{height:'10px', verticalAlign:'bottom'}}>{t('menu.first', {framework:'React'})}</td></tr>
            </table></IconButton></Link>
            <Link to="/data" className={classes.link}><IconButton style={{height:'45px', color:"#000000",border: '0px solid black', fontSize: "10px", verticalAlign:'bottom'}} >
                          <table style={{height:'10px', verticalAlign:'bottom'}}>
            <tr style={{height:'10px', verticalAlign:'bottom'}}><td style={{height:'10px', verticalAlign:'bottom'}}><HubIcon /></td></tr><tr style={{verticalAlign:'bottom',width: '10px',height:'10px'}}><td style={{height:'10px', verticalAlign:'bottom'}}>{t('menu.second', {framework:'React'})}</td></tr>
            </table></IconButton></Link>
            <Link to="/appdev" className={classes.link}><IconButton style={{height:'45px', color:"#000000",border: '0px solid black', fontSize: "10px", verticalAlign:'bottom'}} >
                          <table style={{height:'10px', verticalAlign:'bottom'}}>
            <tr style={{height:'10px', verticalAlign:'bottom'}}><td style={{height:'10px', verticalAlign:'bottom'}}><DeveloperModeIcon /></td></tr><tr style={{verticalAlign:'bottom',height:'10px'}}><td style={{height:'10px', verticalAlign:'bottom'}}>{t('menu.third', {framework:'React'})}</td></tr>
            </table></IconButton></Link>
            <Link to="/consultation" className={classes.link}><IconButton style={{height:'45px', color:"#000000",border: '0px solid black', fontSize: "10px", verticalAlign:'bottom'}} >
                          <table style={{height:'10px', verticalAlign:'bottom'}}>
            <tr style={{height:'10px', verticalAlign:'bottom'}}><td style={{height:'10px', verticalAlign:'bottom'}}><DomainVerificationIcon /></td></tr><tr style={{verticalAlign:'bottom',height:'10px'}}><td style={{height:'10px', verticalAlign:'bottom'}}>{t('menu.fourth', {framework:'React'})}</td></tr>
            </table></IconButton></Link>
            
              
                </div>
              <Notify errorMessage={errorMessage} />
              <Switch>
                <Route path="/home">
                  <br />
                  <Home />
                  <br /><br /><br /><br />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/prices">
                  <Prices />
                </Route>
                <Route path="/gdpr">
                  <Gdpr />
                </Route>
                <Route path="/info">
                  <Info  />
                </Route>
                <Route path="/termsofuse">
                  <Termsofuse   />
                </Route>
                <Route path="/hubioy">
                  <br />
                  <Hubioy   />
                </Route>
                <Route path="/data">
                  <br />
                  <Data    />
                </Route>
                <Route path="/appdev">
                  <br />
                  <Appdev   />
                </Route>
                <Route path="/consultation">
                  <br />
                  <Consultation   />
                </Route>
                <Route path="/">
                  <Home/>
                  <br /><br /><br /><br />
                </Route>
                <Route path="/*">
                  <Home/>
                  <br /><br /><br /><br />
                </Route>
              </Switch>
              <Footer style={{zIndex:10}} />
            </Router>
          
          </div>
          </Container>
          </Suspense>
        </>
      )
}

export default App

//<Headerpublic style={{zIndex:8}} />

/**
 * 
 * 
 * <div style={{ backgroundRepeat: 'no-repeat',height:'200px',width:'99%',top:'30px',
          backgroundImage:`url(${ylabanner})`,marginLeft:'-10px', zIndex:12, border: '0px solid #12343b',borderRadius: '2px',
                    opacity: 0.99, fontSize:'14px', fontFamily:'Helvetica', textAlign:'left'}}>
              
              </div>
 */