import React, { useState } from 'react'

import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const Appdev = ({ createComment }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  return (
    <div style={{zIndex:1}}>
      <h2 className="font-face-gm">{t('apps.header1', {framework:'React'})}</h2> 
      <ul className="font-face-gm-text-left2">{t('apps.text1', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('apps.text2', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('apps.text3', {framework:'React'})}</ul>
    </div>
  )
}
export default Appdev