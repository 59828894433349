import React, { useState } from 'react'

import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const Data = ({ createComment }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  return (
    <div style={{zIndex:1}}>
      <h2 className="font-face-gm">{t('data.header1', {framework:'React'})}</h2>
      <h3 className="font-face-gm3">{t('data.header2', {framework:'React'})}</h3> 
      <ul className="font-face-gm-text-left2">{t('data.text1', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('data.text2', {framework:'React'})}</ul> 
      <ul className="font-face-gm-text-left2">{t('data.text3', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">🔗 {t('data.text4', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">🌐 {t('data.text5', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">📊 {t('data.text6', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">🔌 {t('data.text7', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('data.text8', {framework:'React'})}</ul>
      <h3 className="font-face-gm3">{t('data.header3', {framework:'React'})}</h3> 
      <ul className="font-face-gm-text-left2">{t('data.text9', {framework:'React'})}</ul>
      <h3 className="font-face-gm3">{t('data.header4', {framework:'React'})}</h3> 
      <ul className="font-face-gm-text-left2">{t('data.text10', {framework:'React'})}</ul>
      
      <br /><br /><br /><br /><br /><br />
    </div>
    
  )
}
export default Data

/**
 * 
 * <h2>{t('common.info')}</h2> 
      <p>{t('common.takecontact')}</p>
<br /><br />
      <p>{t('common.usage')}</p>
      <p>{t('common.contactinfo')}</p> 
 */