import React, { useState } from 'react'

import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import osmo from '../pics/osmo2.png'


const Hubioy = ({ createComment }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')


  return (
      <div style={{zIndex:1}}>
        <h2 className="font-face-gm">{t('hubioy.header1', {framework:'React'})}</h2> 
        <ul className="font-face-gm-text-left2">{t('hubioy.text1', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text2', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text3', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text4', {framework:'React'})}</ul>
        <tr style={{alignItems:'top'}}><td style={{alignItems:'top'}}>
        <h2 className="font-face-gm">{t('hubioy.header2', {framework:'React'})}</h2>
        <ul className="font-face-gm-text-left2">Osmo Leppäniemi</ul>
        <ul className="font-face-gm-text-left2">osmo@hubioy.fi</ul>
        <ul className="font-face-gm-text-left2">+358 10 582 9810</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text5', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text6', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text7', {framework:'React'})}</ul>
        <ul className="font-face-gm-text-left2">{t('hubioy.text8', {framework:'React'})}</ul></td>
        

        </tr>
        <img src={osmo} alt="Osmo"/>
        <br /><br /><br /><br /><br />      
      </div>
    )
}
export default Hubioy