import React, { useState } from 'react'

import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const Consultation = ({ createComment }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  return (
    <div style={{zIndex:1}}>
      <h2 className="font-face-gm">{t('consult.header', {framework:'React'})}</h2> 
      <ul className="font-face-gm-text-left2">{t('consult.text1', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text2', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text3', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text4', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text5', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text6', {framework:'React'})}</ul>
      <h2 className="font-face-gm">{t('consult.header2', {framework:'React'})}</h2>
      <ul className="font-face-gm-text-left2">{t('consult.text7', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text8', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text9', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text10', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text11', {framework:'React'})}</ul>
      <ul className="font-face-gm">{t('consult.header3', {framework:'React'})}</ul> 
      <ul className="font-face-gm-text-left2">{t('consult.text12', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text13', {framework:'React'})}
      <br />{t('consult.text13_1', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text14', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text15', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text16', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text17', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text18', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text19', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text20', {framework:'React'})}</ul>
      <ul className="font-face-gm-text-left2">{t('consult.text21', {framework:'React'})}</ul>
      <ul className="font-face-gm3">{t('consult.header4', {framework:'React'})}</ul>
            <br /><br /><br />
    </div>
  )
}
export default Consultation